import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import useAccordionStyles from "../../../../../components/AccordionModal/styles";
import { KeyValueSeparator } from "../../../../../components/AccordionRowDetails";
import CustomModal from "../../../../../components/CustomModal";

import applicationConfig from "../../../../../config/applicationConfig";

import { splitCamelCaseString } from "../../../../../utilities/formValidation";

import useStyles from "./styles";

const ConsentMappingsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
}) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const history = useHistory();
  const accordionClasses = useAccordionStyles();

  const { consentMappings, optTraits, consentVersions } = data;

  const rows = Object.keys(data);
  let rowsConsentDetails = Object.keys(data);
  if (data.consentDetails !== undefined) {
    rowsConsentDetails = data?.consentDetails;
  }

  const mappings = consentMappings?.map((x) => ({
    ...x,
    contactPointTypeCode: `${x.contactPointTypeCode} - ${x.contactPointTypeName}`,
    contactPointCategoryCode: `${x.contactPointCategoryCode} - ${x.contactPointCategoryDescription}`,
    contactPointTypeName: x.contactPointTypeName?.split(" ")[0],
    cdpMarketingProgramDescription: `${x.cdpMarketingProgramNumber} - ${x.cdpMarketingProgramDescription}`,
  }));

  const renderUi = () => {
    if (tab === 0) {
      return (
        <table className={globalClasses.table}>
          <tbody>
            {Object.keys(rowsConsentDetails).map((row, ind) => {
              let val = Object.values(rowsConsentDetails)[ind];
              if (ind === 0) {
                val = `${Object.values(rowsConsentDetails)[ind]}`;
              }
              if (ind === 7) {
                val = `${moment(Object.values(rowsConsentDetails)[7])?.format(
                  "YYYY-MM-DD HH:mm:ss"
                )}`;
              }

              let label = row;
              const camelCase = splitCamelCaseString(label);
              label = camelCase[0].toUpperCase() + camelCase.slice(1);
              return (
                <KeyValueSeparator
                  keyVal={label}
                  value={val}
                  ind={ind.toString()}
                  separator={null}
                />
              );
            })}
          </tbody>
        </table>
      );
    }

    if (tab === 1) {
      return consentVersions.length > 0 ? (
        <AccordionModalDetails
          keys={[
            {
              name: t("consent_container.modals.versions_modal.version_number"),
              id: "templateVersionNumber",
            },
            {
              name: t("consent_container.modals.versions_modal.country"),
              id: "country",
            },
            {
              name: t("consent_container.modals.versions_modal.language"),
              id: "language",
            },
            {
              name: t("consent_container.modals.versions_modal.consentText"),
              id: "consentText",
              copyValue: true,
            },
            {
              name: t("consent_container.modals.versions_modal.noticeText"),
              id: "noticeText",
              copyValue: true,
            },
            {
              name: t("consent_container.modals.versions_modal.status"),
              id: "optStatus",
            },
            {
              name: t("consent_container.modals.versions_modal.startDate"),
              id: "optStartDate",
            },
            {
              name: t("consent_container.modals.versions_modal.endDate"),
              id: "optEndDate",
            },
          ]}
          rows={consentVersions.map((x) => ({
            ...x,
            optStartDate: x.optStartDate
              ? `${moment(x.optStartDate)?.format("YYYY-MM-DD HH:mm:ss")}`
              : "",
            optEndDate: x.optEndDate
              ? `${moment(x.optEndDate)?.format("YYYY-MM-DD HH:mm:ss")}`
              : "",
          }))}
          rowKeys={{
            title: "language",
            subTitle: "consentText",
          }}
        />
      ) : (
        <Typography variant="body2">No Consent Text found</Typography>
      );
    }

    if (tab === 2) {
      return consentMappings.length > 0 ? (
        <AccordionModalDetails
          keys={[
            {
              name: t("common.labels.marketing_program"),
              id: "cdpMarketingProgramDescription",
            },
            {
              name: "Channel",
              id: "contactPointTypeCode",
            },
            {
              name: "Contact Point Category",
              id: "contactPointCategoryCode",
            },
            {
              name: t("common.labels.service_name"),
              id: "serviceName",
            },
            {
              name: t(
                "opts_container.modals.text_mapping_modal.primary_indicator"
              ),
              id: "primaryIndicator",
            },
            {
              name: t(
                "opts_container.modals.text_mapping_modal.subscription_opt_number"
              ),
              id: "subscriptionOptNumber",
            },
          ]}
          rows={mappings}
          rowKeys={{
            title: "contactPointTypeName",
            subTitle: "cdpMarketingProgramDescription",
          }}
        />
      ) : (
        <Typography variant="body2">No Opt Mappings found</Typography>
      );
    }

    return optTraits.length > 0 ? (
      <AccordionModalDetails
        keys={[
          {
            name: t("common.labels.marketing_program"),
            id: "cdpMarketingProgramDescription",
          },
          {
            name: "Service Name",
            id: "serviceNames",
          },
        ]}
        type="traitNames"
        rows={optTraits}
        rowKeys={{
          title: "cdpMarketingProgramDescription",
          subTitle: "traitName",
        }}
      />
    ) : (
      <Typography variant="body2">No Opt Traits mapped</Typography>
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      data={{
        consentDetails: rows,
        consentMappings,
        optTraits,
        consentVersions,
      }}
      subtitle={subtitle}
      loading={loading}
      contentClassName={classes.optModalContents}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
              root: classes.tab,
            }}
          >
            <Tab label="DETAILS" />
            <Tab label="CONSENT TEXT" />
          </Tabs>
          {renderUi()}
        </div>
        <div className={accordionClasses.footer2}>
          <Button
            data-testid="closeButton"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => {
              history.push({
                pathname: applicationConfig.pathnames.consent_manage,
                state: data,
              });
            }}
          >
            Manage Consent
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

ConsentMappingsModal.defaultProps = {
  title: null,
};

ConsentMappingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    consentDetails: PropTypes.shape([]).isRequired,
    consentMappings: PropTypes.shape([]).isRequired,
    optTraits: PropTypes.shape([]).isRequired,
    consentVersions: PropTypes.shape([]).isRequired,
    ciamOptId: PropTypes.string.isRequired,
    optTextEnglish: PropTypes.string.isRequired,
    optTextLocalLanguage: PropTypes.string.isRequired,
    ciamLegalEntityId: PropTypes.number.isRequired,
    ciamMarketingProgramNumber: PropTypes.number.isRequired,
    ciamMarketingProgramDescription: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConsentMappingsModal;
