import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: `${theme.palette.background.default1} !important`,
      },
    },
    optMappingContainer: {
      "& > *": {
        marginBottom: "15px",
      },
    },
    newConsentContainer: {
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    consentDialog: {
      marginTop: "10px",
    },
    title: {
      fontSize: "x-large",
      fontWeight: "bold",
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.palette.text.primary,
      },
      "& > *": {
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    fullWidth: {
      width: "100%",
    },
    accordion: {
      border: `1px solid ${theme.colorTheme.greyishWhite}`,
      borderRadius: "4px",
      marginBottom: 16,
      width: "100%",
    },
    accordionHeader: {
      padding: "0 16px",
      "& .MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
      },
    },
    accordionContent: {
      padding: 16,
      paddingBottom: 0,
      borderTop: `1px solid ${theme.colorTheme.greyishWhite}`,
      background: theme.colorTheme.white1,
      margin: "0 !important",
      display: "flex",
      flexDirection: "column",
      "& div:nth-child(2)": {
        "& p:nth-child(2)": {
          width: "100%",
        },
      },
    },
    statusLabel: {
      marginLeft: "auto",
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
  };
});

export default useStyles;
