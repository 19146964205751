import { useState, useEffect } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import useGlobalStyles from "../../../../assets/styles/global";

import useAccordionStyles from "../../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CustomModal from "../../../../components/CustomModal";

import consentsModuleConfig from "../../../../config/consentsModuleConfig";
import languages from "../../../../config/languages";

import replaceValuesWithPlaceholders from "../helpers/replaceValuesWithPlaceholders";
import updateQuillContent from "../helpers/updateQuillContext";

const ReactQuill = require("react-quill");

const wrapWithClass = (htmlText, tag, className) => {
  const regex = new RegExp(
    `<${tag}(?![^>]*class=['"][^'"]*${className}['"][^>]*)(.*?)>`,
    "g"
  );
  const wrappedText = htmlText.replace(
    regex,
    `<${tag} class='${className}'$1>`
  );

  if (!htmlText.match(new RegExp(`<${tag}[^>]*>`))) {
    return `<${tag} class='${className}'>${htmlText}</${tag}>`;
  }

  return wrappedText;
};

const TemplateTexts = ({
  updatedConsentTexts,
  isDisabled,
  templateVariablesValues,
  onSubmit,
  openAccordion = false,
}) => {
  const { consentTemplateKeys } = consentsModuleConfig;
  const accordionClasses = useAccordionStyles();
  const [showModal, setShowModal] = useState(false);
  const [consentInfo, setConsentInfo] = useState({});
  const globalStyles = useGlobalStyles();
  const [consentTextMap, setConsentTextMap] = useState(updatedConsentTexts);
  const [openOnCloseWarningModal, setOpenOnCloseWarningModal] = useState(false);
  const [expandAccordions, setExpandAccordions] = useState({});
  const [groupedConsentTexts, setGroupedConsentTexts] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setConsentTextMap(updatedConsentTexts);
    setGroupedConsentTexts(
      Object.groupBy(
        Object.values(updatedConsentTexts),
        ({ consentTemplateType }) => consentTemplateType
      )
    );
  }, [updatedConsentTexts]);

  useEffect(() => {
    if (openAccordion) {
      const updatedValues = Object.keys(groupedConsentTexts).reduce(
        (acc, key) => {
          groupedConsentTexts[key].forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            acc[item.consentTemplateId] = true;
          });
          return acc;
        },
        {}
      );
      setExpandAccordions((prev) => ({ ...prev, ...updatedValues }));
    } else {
      setExpandAccordions({});
    }
  }, [openAccordion, groupedConsentTexts]);

  const toggleExpandAccordion = (id, value) => {
    setExpandAccordions((prev) => ({ ...prev, [id]: value }));
  };

  const groupingConsentTexts = Object.keys(groupedConsentTexts)?.map(
    (groupingKey) => {
      const grouping = groupedConsentTexts[groupingKey].map((item) => {
        const consentTextKey = `${item.consentTemplateTypeId}-${item.countryCode}-${item.languageCode}`;
        const language = languages.find(
          (l) => l.languageCode === item?.languageCode?.toLowerCase()
        )?.languageName;

        const { consentText, noticeText, consentTemplateId } = item;
        const expanded = !!expandAccordions[consentTemplateId];

        return (
          <div style={{ paddingTop: 5 }}>
            <div
              className={clsx(globalStyles.flexContainer)}
              style={{ paddingBottom: 0 }}
            >
              <Accordion
                key={`${consentTextKey}`}
                className={clsx(accordionClasses.accordion)}
                expanded={expanded}
                onChange={(_, value) =>
                  toggleExpandAccordion(consentTemplateId, value)
                }
              >
                <AccordionSummary
                  data-testid="accordion-summary"
                  className={accordionClasses.accordionHeader}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={accordionClasses.systemType}>
                    {language}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={accordionClasses.systemName}
                    dangerouslySetInnerHTML={{
                      __html: consentText,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails className={accordionClasses.accordionContent}>
                  <AccordionRowDetails
                    keys={consentTemplateKeys}
                    row={{
                      consentText: updateQuillContent(
                        consentText || "",
                        templateVariablesValues
                      ),
                      noticeText: updateQuillContent(
                        noticeText || "",
                        templateVariablesValues
                      ),
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              {!isDisabled && (
                <IconButton
                  className={globalStyles.editButton}
                  style={{
                    width: 45,
                    height: 45,
                    position: "relative",
                    bottom: "10px",
                  }}
                  onClick={() => {
                    setConsentInfo({
                      consentTextKey,
                      ...item,
                    });
                    setShowModal(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
          </div>
        );
      });
      return (
        <div
          style={{
            border: "1px solid #E0E0E0",
            padding: "20px",
            boxShadow: "0px 2px 3px #00000029",
            borderRadius: "25px",
          }}
        >
          {groupingKey}
          {grouping}
          <span>
            Note: Please double check <b>ALL</b> links before proceeding.
          </span>
        </div>
      );
    }
  );

  const handleConsentTextChange = (value, delta, key) => {
    const updatedText = replaceValuesWithPlaceholders(
      value,
      consentInfo?.consentText || "",
      templateVariablesValues
    );

    if (updatedText !== consentTextMap[key]?.consentText) {
      setConsentTextMap((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          consentText: updatedText,
        },
      }));
    }
  };

  const handleNoticeTextChange = (value, delta, key) => {
    const updatedText = replaceValuesWithPlaceholders(
      value,
      consentInfo?.noticeText || "",
      templateVariablesValues
    );

    if (updatedText !== consentTextMap[key]?.noticeText) {
      setConsentTextMap((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          noticeText: updatedText,
        },
      }));
    }
  };

  const handleSubmit = () => {
    // eslint-disable-next-line no-shadow
    const updatedConsentTexts = { ...consentTextMap };

    Object.keys(updatedConsentTexts).forEach((key) => {
      const consentData = updatedConsentTexts[key];

      if (consentData?.consentText) {
        consentData.consentText = wrapWithClass(
          consentData.consentText,
          "p",
          "crs-consent"
        );
      }

      if (consentData?.noticeText) {
        consentData.noticeText = wrapWithClass(
          consentData.noticeText,
          "div",
          "crs-notice"
        );
      }
    });

    onSubmit(updatedConsentTexts);
    setShowModal(false);
  };

  return (
    <>
      {groupingConsentTexts}
      <CustomModal
        open={showModal}
        onClose={() => {
          setOpenOnCloseWarningModal(true);
        }}
        title={consentInfo?.consentTextKey}
        subtitle="Consent Text & Notice Text is listed below"
        showCloseIcon
      >
        <div style={{ marginBottom: 25 }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Consent Text
          </Typography>
          <ReactQuill
            theme="snow"
            value={updateQuillContent(
              consentTextMap[consentInfo?.consentTextKey]?.consentText || "",
              templateVariablesValues
            )}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onKeyUp={(e) => {
              e.preventDefault();
            }}
            onChange={(value, delta) => {
              handleConsentTextChange(
                value,
                delta,
                consentInfo?.consentTextKey
              );
            }}
            readOnly={isDisabled}
            modules={{
              toolbar: [
                [{ bold: true }, { italic: true }, { underline: true }],
              ],
            }}
          />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Notice Text
          </Typography>
          <ReactQuill
            theme="snow"
            value={updateQuillContent(
              consentTextMap[consentInfo?.consentTextKey]?.noticeText || "",
              templateVariablesValues
            )}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onKeyUp={(e) => {
              e.preventDefault();
            }}
            onChange={(value, delta) => {
              handleNoticeTextChange(value, delta, consentInfo?.consentTextKey);
            }}
            readOnly={isDisabled}
            modules={{
              toolbar: [
                [{ bold: true }, { italic: true }, { underline: true }],
              ],
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            classes={{
              root: globalStyles.btn,
            }}
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
            style={{ marginTop: 20 }}
          >
            Submit
          </Button>
        </div>
      </CustomModal>
      {openOnCloseWarningModal && (
        <ConfirmationModal
          open={openOnCloseWarningModal}
          onClose={() => setOpenOnCloseWarningModal(false)}
          title={t("dialogs.confirm_remove")}
          message="Are you sure you want to close? Any Unsaved changes will be lost."
          btn1Text={t("common.cancel")}
          btn2Text={t("common.ok")}
          btn2Action={async () => {
            setConsentInfo({});
            setConsentTextMap(updatedConsentTexts);
            setShowModal(false);
            setOpenOnCloseWarningModal(false);
          }}
          type="error"
        />
      )}
    </>
  );
};

TemplateTexts.defaultProps = {
  isDisabled: false,
};

TemplateTexts.propTypes = {
  updatedConsentTexts: PropTypes.objectOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool,
  templateVariablesValues: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  openAccordion: PropTypes.bool.isRequired,
};

export default TemplateTexts;
